import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Loader } from "../components";
import {
  LANDING_PAGE,
  TRANSPORT_PAGE,
  HOMES_PAGE,
  ABOUT_PAGE,
} from "./constants";

const Landing = lazy(() => import("../pages/landingPage/LandingPage"));
const Transport = lazy(() => import("../pages/transportPage/TransportPage"));
const Homes = lazy(() => import("../pages/homesPage/HomesPage"));
const About = lazy(() => import("../pages/about/About"));

const RouterConfig = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path={LANDING_PAGE} exact component={Landing} />
        <Route path={TRANSPORT_PAGE} exact component={Transport} />
        <Route path={HOMES_PAGE} exact component={Homes} />
        <Route path={ABOUT_PAGE} exact component={About} />
      </Switch>
    </Suspense>
  );
};

export default RouterConfig;
