import RouterConfig from "./navigations/RouterConfig";

const App = () => {
  return (
    <>
      <RouterConfig />
    </>
  );
};

export default App;
