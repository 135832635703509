import { Hamburger, LinkTag, NavbarLink } from "..";
import { brand } from "../../assets";
import { FiChevronDown } from "react-icons/fi";
import { Submenu1 } from "../submenu/Submenu";
import "./header.scss";
import { useState } from "react";

const Header = () => {
  const [showNav, setShowNav] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleShowNav = () => {
    setShowNav(!showNav);
  };

  const handleDropdown = () => {
    if (window.innerWidth < 992) {
      setDropdown(!dropdown);
    }
  };
  return (
    <header className="header">
      <div className="header__container container">
        <div className="header__brand">
          <LinkTag to="/">
            <img src={brand} alt="" />
          </LinkTag>
        </div>
        <nav className={`header__nav ${showNav ? "active" : ""}`}>
          <NavbarLink exact to="/">
            Home
          </NavbarLink>
          <NavbarLink to="/about">About Us</NavbarLink>
          <div className="header__navDropdown">
            <div className="header__navlink" onClick={handleDropdown}>
              <span className="header__navText">Champion Group</span>
              <FiChevronDown className="header__navlinkArrow" />
            </div>
            <div className={`header__submenu ${dropdown ? "active" : ""}`}>
              <Submenu1
                submenuData={[
                  {
                    label: "Champion Homes and Apartment Ltd",
                    to: "/homes",
                  },
                  {
                    label: "Champion Transport Ltd",
                    to: "/transport",
                  },
                  {
                    label: "Champion One Entertainment",
                    externalLink: "https://champion1ent.com/",
                  },
                  {
                    label: "Proteage Ventures Ltd",
                    externalLink: "/",
                  },
                ]}
              />
            </div>
          </div>
        </nav>
        <Hamburger toggleFunc={handleShowNav} toggle={showNav} />
      </div>
    </header>
  );
};

export default Header;
