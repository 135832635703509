import { SocialIcons } from "..";
import { brand } from "../../assets";
import { FaInstagram } from "react-icons/fa";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <div className="footer__head">
          <div className="footer__brand">
            <img src={brand} alt="" />
          </div>
        </div>
        <div className="footer__body">
          <div className="footer__socials">
            <h3 className="footer__socialsHeader">Connect with us</h3>
            <div className="footer__socialsContainer">
              <SocialIcons to="https://www.instagram.com/championgroupltd/">
                <FaInstagram />
              </SocialIcons>
            </div>
          </div>
          <div className="footer__details">
            <a href="tel:+2349134045224" className="footer__detail">
              +234 913 404 5224
            </a>
            <a
              href="mailto:info@championgroupltd.com"
              className="footer__detail"
            >
              info@championgroupltd.com
            </a>
            <p className="footer__detail">
              6 Adegbenle Bolarinwa street, Chevy View Estate, off Chevron Drive
              Lekki
              <br />
              <br /> Lagos - Nigeria
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
